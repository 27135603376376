/* istanbul ignore file */
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormTextarea } from 'components/FormTextarea';
import { SalesOrder } from 'types/salesOrders';
import { SectionCollapsable } from 'components/SectionCollapsable';

type Props = {
  tabIndex?: number;
};

export const GiftMessageForm: React.FC<Props> = ({ tabIndex }) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <Box mt={4}>
      <SectionCollapsable title="Gift Message" defaultIsOpen={false}>
        <FormTextarea
          {...register('giftMessage')}
          aria-label="Gift Message"
          marginTop="12px"
          maxLength={500}
          placeholder="Optional: Enter a gift message that will reflect on the packing slip"
          rows={2}
          tabIndex={tabIndex}
        />
      </SectionCollapsable>
    </Box>
  );
};
