export const SALES_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1xvs3HWOXHkUAVO4TIKcbJcuSCwsc1tGaOd4w6Jgm76g/edit?usp=sharing';
export const PURCHASE_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1W51V596Xed44KrbvlqBRYBspILVQH80UCsghjIyy7po/edit?usp=sharing';
export const FORECAST_URL =
  'https://docs.google.com/spreadsheets/d/1G4lqBseBvAU3QWhO0Hem6P8DaBT__fn6IJRibMVsLuI/edit?usp=sharing';
export const SHIPPING_ACCOUNT_SETUP_URL =
  'https://docs.google.com/spreadsheets/d/1oEjRiNX2T4TxcXOkT-hftOIiP00YEezdxpScgziwpaA/edit?usp=sharing';
export const CREATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1sjRQS0XhuC2oWKtdFiq-xDtuq1xV_IHbbIIztTYIYiw/edit?usp=sharing';
export const UPDATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1S06eTN2Ic_wKpmrUChe6-TWPv-f0tOjLwa7zhKZL1Kw/edit?usp=sharing';
