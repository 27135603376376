import {
  Box,
  useToast,
  Stack,
  Text,
  TextProps,
  StackProps,
} from '@chakra-ui/react';
import MailToIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';

interface CopyableTextProps extends StackProps {
  children?: React.ReactNode;
  text: string;
  copiedMessage: string;
  isExpandable?: boolean;
  titleButton?: string;
  textProps?: TextProps;
}

export const CopyableText: React.FC<CopyableTextProps> = ({
  children,
  text,
  copiedMessage,
  isExpandable,
  titleButton,
  textProps,
  ...props
}) => {
  const toast = useToast();
  const [isExpanding, setIsExpanding] = useState(true);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    toast({
      status: 'info',
      description: copiedMessage,
    });
  };

  return (
    <Stack direction="row" spacing={2} {...props}>
      <Text
        fontWeight="medium"
        sx={{
          textWrap: isExpandable ? 'wrap' : '',
          maxHeight: isExpandable && isExpanding ? '20px' : '',
          overflow: isExpandable && isExpanding ? 'hidden' : '',
          ...textProps?.sx,
        }}
        {...textProps}
      >
        {children ?? text}
      </Text>
      {isExpandable && (
        <Box
          cursor="pointer"
          title={isExpanding ? 'Click to Expand' : 'Click to Collapse'}
        >
          {isExpanding ? (
            <KeyboardArrowDownIcon
              fontSize="small"
              onClick={() => setIsExpanding(!isExpanding)}
            />
          ) : (
            <KeyboardArrowUpIcon
              fontSize="small"
              onClick={() => setIsExpanding(!isExpanding)}
            />
          )}
        </Box>
      )}

      <Box
        onClick={copyToClipboard}
        cursor="pointer"
        title={titleButton ? titleButton : text}
      >
        <MailToIcon fontSize="small" />
      </Box>
    </Stack>
  );
};
