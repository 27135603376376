import { Center, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, LinkProps } from './Link';
import { Shimmer } from './Shimmer';

interface StatLinkProps extends LinkProps {
  number: number;
  label: string;
  isLoading?: boolean;
}

export const StatLink = ({
  number,
  label,
  isLoading,
  color = 'secondaryOrange.500',
  ...linkProps
}: StatLinkProps) => {
  return (
    <Stat size="4xl">
      <Link color={color} {...linkProps}>
        <Center fontSize="md">
          <Shimmer h="30px" w="60px" my={3} isLoaded={!isLoading}>
            <StatNumber mr={1.5}>{number.toLocaleString('en-US')}</StatNumber>
            <LaunchIcon fontSize="inherit" />
          </Shimmer>
        </Center>
      </Link>
      <StatLabel>{label}</StatLabel>
    </Stat>
  );
};
