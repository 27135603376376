import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { PendingOrders } from 'features/dashboard/components/PendingOrders/PendingOrders';
import { DashboardCard } from 'components/DashboardCard';
import { InventorySummary } from 'features/dashboard/components/InventorySummary/InventorySummary';
import withLayout from 'hoc/withLayout';
import { PENDING_STATUSES } from 'constants/order-statuses';

function DashboardComponent() {
  return (
    <Box mx={{ base: 2, xl: 8 }}>
      <Flex gap={8} direction={{ base: 'column', lg: 'row' }}>
        <Flex direction="column" flex={1} gap={8}>
          <DashboardCard
            title="Pending Orders"
            primaryCTA={{
              label: 'View Orders',
              href: `/orders?latest_status=${PENDING_STATUSES.join(',')}`,
            }}
          >
            <PendingOrders />
          </DashboardCard>
        </Flex>
        <Flex direction="column" flex={1} gap={8}>
          <DashboardCard
            title="Inventory Summary"
            primaryCTA={{
              label: 'View Inventory',
              href: '/inventory',
            }}
          >
            <InventorySummary />
          </DashboardCard>
        </Flex>
      </Flex>
      <Outlet />
    </Box>
  );
}

export const DashboardPage = withLayout(DashboardComponent);
