/* istanbul ignore file */
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormTextarea } from 'components/FormTextarea';
import { SalesOrder } from 'types/salesOrders';
import { SectionCollapsable } from 'components/SectionCollapsable';

type Props = {
  tabIndex?: number;
};

export const InstructionsForm: React.FC<Props> = ({ tabIndex }) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <Box mt={4}>
      <SectionCollapsable title="Instructions" defaultIsOpen={false}>
        <FormTextarea
          tabIndex={tabIndex}
          aria-label="Instructions"
          {...register('instructions')}
          rows={2}
          placeholder="Optional: Add a note"
          marginTop="12px"
        />
      </SectionCollapsable>
    </Box>
  );
};
