/* istanbul ignore file */
import { SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { SalesOrder } from 'types/salesOrders';
import { FormSwitch } from 'components/FormSwitch';

type Props = {
  editable?: boolean;
  startTabIndex: number;
};

export const BolDataForm: React.FC<Props> = ({
  editable = true,
  startTabIndex,
}) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <SimpleGrid columns={[1, 1, 2]} columnGap={8}>
      <FormInput
        tabIndex={startTabIndex + 1}
        label="BOL Number"
        {...register('bolData.bolNumber')}
        placeholder="Please enter a number"
        isReadOnly={!editable}
      />
      <FormSwitch
        tabIndex={startTabIndex + 2}
        label="FOB?"
        {...register('bolData.fob')}
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 3}
        label="Ship-to CID number"
        {...register('bolData.shipToCidNumber')}
        placeholder="Please enter a number"
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 4}
        label="Ship-to location number"
        {...register('bolData.shipToLocationNumber')}
        placeholder="Please enter a number"
        isReadOnly={!editable}
      />
    </SimpleGrid>
  );
};
