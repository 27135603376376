import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Warehouse, WarehouseDto } from 'types/warehouses';

export const SELECT_NODE_OPTION = {
  name: 'Automatic',
  displayText: 'Automatic',
  code: 'SELECT_NODE',
};

export function isSelectNodeOption(value: string) {
  return value === SELECT_NODE_OPTION.code;
}

type QueryOptions = Parameters<typeof useQuery<Warehouse[]>>[2] & {
  includeSelectNodeOption?: boolean;
};

export function useWarehouses({
  includeSelectNodeOption,
  ...queryOptions
}: QueryOptions = {}) {
  return useQuery<Warehouse[]>(
    ['warehouses', { includeSelectNodeOption }],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: WarehouseDto[] }>('/warehouses');

      const warehouse = items
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((item) => ({
          ...item,
          displayText: `${item.code} - ${item.address?.city} ${item.address?.state}`,
        }));
      if (includeSelectNodeOption) {
        return [SELECT_NODE_OPTION, ...warehouse];
      }
      return warehouse;
    },
    queryOptions
  );
}
