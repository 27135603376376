/* istanbul ignore file */
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { BasicInfoForm } from './BasicInfoForm';
import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';
import { OrderLines } from 'features/order-lines/OrderLines';
import { InstructionsForm } from './InstructionsForm';
import { SectionCollapsable } from 'components/SectionCollapsable';
import { GiftMessageForm } from './GiftMessageForm';
import CarrierAccountSelect from './CarrierAccountSelect';

export const CreateParcelOrderForm: React.FC = () => {
  return (
    <SimpleGrid columns={1}>
      <GridItem>
        <SectionCollapsable title="General">
          <BasicInfoForm orderType="Parcel" startTabIndex={0} />
        </SectionCollapsable>
      </GridItem>
      <GridItem mt={8}>
        <SectionCollapsable title="Ship To">
          <ShipToForm startTabIndex={9} />
          <InstructionsForm tabIndex={18} />
          <GiftMessageForm tabIndex={19} />
        </SectionCollapsable>
        <CarrierAccountSelect tabIndex={20} />
      </GridItem>
      <GridItem mt={8}>
        <SectionCollapsable title="SKUs and quantities to ship">
          <OrderLines startTabIndex={21} />
        </SectionCollapsable>
      </GridItem>
    </SimpleGrid>
  );
};
