import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { useHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { CreateOrder } from './components/CreateOrder';
import { usePortal } from 'hooks/usePortal';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { BigLoading } from 'components/BigLoading';

type Props = {
  onClose: () => void;
};

export const CreateOrderModal: React.FC<Props> = (props) => {
  const { onOpen: handleOpen, ...disclosure } = useDisclosure();
  const [handleClose, setCloseInterceptor] = useHandlerInterceptor(() => {
    disclosure.onClose();
    props.onClose();
  });

  const { isPortalReadOnly } = usePortal();

  const { isLoading } = useShipVias();

  useEffect(() => {
    !isPortalReadOnly && handleOpen();
  }, [handleOpen, isPortalReadOnly]);

  return (
    <Modal
      fullscreen
      title="Create New Order"
      isOpen={disclosure.isOpen}
      onClose={handleClose}
      buttons={[]}
      size={['full', null, '7xl']}
    >
      {!isLoading ? (
        <CreateOrder
          onAbort={props.onClose}
          onConfirm={props.onClose}
          setCloseInterceptor={setCloseInterceptor}
        />
      ) : (
        <BigLoading />
      )}
    </Modal>
  );
};
