/* istanbul ignore file */
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth0ProviderWithRedirectCallback } from 'auth0-provider';
import { theme } from 'theme/theme';
import { ConfirmationDialogContextProvider } from 'contexts/confirmationDialogContext';
import { OrderDetailsPage } from 'pages/OrderDetailsPage';
import { CreateOrderPage } from 'pages/CreateOrderPage';
import { PurchaseOrderDetailsPage } from 'pages/PurchaseOrderDetailsPage';
import { NotImplementedPage } from 'pages/NotImplementedPage';
import { OrdersPage } from 'pages/OrdersPage';
import { DashboardPage } from 'pages/DashboardPage';
import { AdminPage } from 'pages/AdminPage';
import { InventoryPage } from 'pages/InventoryPage';
import { InventoryItemDetailsPage } from 'pages/InventoryItemDetailsPage';
import { PurchaseOrderPage } from 'pages/PurchaseOrderPage';
import { SchedulePurchaseOrderPage } from 'pages/SchedulePurchaseOrderPage';
import { MainLayout } from 'layouts/MainLayout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { AsnPage } from 'pages/AsnPage';
import { ReturnsPage } from 'pages/ReturnsPage';
import { CONFIG } from 'utils/config';
import TemplatesPage from 'pages/TemplatesPage';
import { toastOptions } from 'theme/toast-options';
import { StoreProvider } from 'contexts/storeContext';
import { ItemMastersPage } from 'pages/ItemMastersPage';
import { UploadItemMastersPage } from 'pages/UploadItemMastersPage';
import { MsaDocumentsPage } from 'pages/MsaDocumentsPage';
import { TransportationDetailsPage } from 'pages/TransportationDetailsPage';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { BulkCancelPage } from 'pages/BulkCancelPage';

const CACHE_STALE_TIME = 90000;
const THREE_SECONDS = 3000;
const MAX_RETRIES = 2;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: CACHE_STALE_TIME,
      retry: (failureCount, error) => {
        return (
          failureCount < MAX_RETRIES && (error as any).response.status !== 404
        );
      },
      retryDelay: THREE_SECONDS,
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} toastOptions={toastOptions}>
      <ConfirmationDialogContextProvider>
        <BrowserRouter>
          <StoreProvider>
            <Auth0ProviderWithRedirectCallback>
              <Routes>
                <Route
                  path="/"
                  element={<ProtectedRoute component={MainLayout} />}
                >
                  <Route index element={<Navigate replace to="dashboard" />} />
                  <Route
                    path="dashboard"
                    element={<ProtectedRoute component={DashboardPage} />}
                  >
                    <Route path=":salesOrderNumber">
                      <Route
                        path="edit"
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                      <Route
                        path="cancel"
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="orders"
                    element={<ProtectedRoute component={OrdersPage} />}
                  >
                    <Route path=":salesOrderNumber">
                      <Route
                        path="edit"
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                      <Route
                        path="cancel"
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ProtectedRoute component={OrderDetailsPage} />
                        }
                      />
                    </Route>
                    <Route
                      path="create"
                      element={<ProtectedRoute component={CreateOrderPage} />}
                    />
                    <Route
                      path="bulk-cancel"
                      element={
                        <ProtectedRoute
                          component={BulkCancelPage}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="inventory"
                    element={<ProtectedRoute component={InventoryPage} />}
                  >
                    <Route
                      path=":sku"
                      element={
                        <ProtectedRoute component={InventoryItemDetailsPage} />
                      }
                    />
                  </Route>
                  <Route
                    path="admin"
                    element={
                      <ProtectedRoute
                        component={AdminPage}
                        requireOneOfRoles={[
                          AUTH0_ROLES.SUPER_USER,
                          AUTH0_ROLES.STANDVAST_ADMIN,
                        ]}
                      />
                    }
                  />
                  <Route
                    path="inbound-inventory"
                    element={<ProtectedRoute component={PurchaseOrderPage} />}
                  >
                    <Route path=":purchaseOrderNumber">
                      <Route
                        path="edit"
                        element={
                          <ProtectedRoute
                            component={PurchaseOrderDetailsPage}
                          />
                        }
                      />
                      <Route
                        path="cancel"
                        element={
                          <ProtectedRoute
                            component={PurchaseOrderDetailsPage}
                          />
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ProtectedRoute
                            component={PurchaseOrderDetailsPage}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="schedule"
                      element={
                        <ProtectedRoute component={SchedulePurchaseOrderPage} />
                      }
                    />
                  </Route>
                  <Route
                    path="team-admin"
                    element={<ProtectedRoute component={NotImplementedPage} />}
                  />
                  {CONFIG().featureToggles.enableAsnReport ? (
                    <Route
                      path="/report/asns"
                      element={<ProtectedRoute component={AsnPage} />}
                    />
                  ) : null}
                  {CONFIG().featureToggles.enableTransportationDetailsReport ? (
                    <Route
                      path="/report/transportation-details"
                      element={
                        <ProtectedRoute
                          component={TransportationDetailsPage}
                          requireOneOfRoles={[
                            AUTH0_ROLES.STANDVAST_ADMIN,
                            AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                          ]}
                        />
                      }
                    />
                  ) : null}
                  {CONFIG().featureToggles.enableReturnsReport ? (
                    <Route
                      path="/report/returns"
                      element={<ProtectedRoute component={ReturnsPage} />}
                    />
                  ) : null}
                  <Route
                    path="templates"
                    element={<ProtectedRoute component={TemplatesPage} />}
                  />
                  {CONFIG().featureToggles.enableItemMaster ? (
                    <Route
                      path="item-masters"
                      element={
                        <ProtectedRoute
                          component={Outlet}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    >
                      <Route
                        index
                        element={
                          <ProtectedRoute
                            component={ItemMastersPage}
                            requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="create"
                        element={
                          <ProtectedRoute component={UploadItemMastersPage} />
                        }
                      />
                      <Route
                        path="update"
                        element={
                          <ProtectedRoute component={UploadItemMastersPage} />
                        }
                      />
                    </Route>
                  ) : null}
                  <Route
                    path="msa"
                    element={
                      <ProtectedRoute
                        component={MsaDocumentsPage}
                        requireOneOfRoles={[
                          AUTH0_ROLES.STANDVAST_ADMIN,
                          AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                        ]}
                      />
                    }
                  />
                </Route>
                <Route
                  path="*"
                  element={<ProtectedRoute component={NotImplementedPage} />}
                />
              </Routes>
            </Auth0ProviderWithRedirectCallback>
          </StoreProvider>
        </BrowserRouter>
      </ConfirmationDialogContextProvider>
    </ChakraProvider>
  </QueryClientProvider>
);
