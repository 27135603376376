import { useNavigate, useLocation } from 'react-router-dom';
import { CreateOrderModal } from 'features/create-order/CreateOrderModal';
import { CONFIG } from 'utils/config';

export function CreateOrderPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const handleClose = () => {
    navigate(`/${firstUrlSegment}`);
  };

  return CONFIG().featureToggles.enableCreateSalesOrder ? (
    <CreateOrderModal onClose={handleClose} />
  ) : null;
}
