import { Download } from '@mui/icons-material';
import { useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { sleep } from './timerUtils';
import {
  CreateReportResponse,
  DownloadReportErrorResponse,
  DownloadReportFailureResponse,
  DownloadReportResponse,
  JsonArray,
  ReportParameter,
  Reports,
} from 'types/report';
import { ToastId, useToast } from '@chakra-ui/react';
import _ from 'lodash-es';
import { HttpStatusCode } from 'constants/httpStatusCodes';
import { REPORT_FILENAMES } from 'constants/reports';
import { formatDate } from './dateUtils';
import { FileType } from './fileTypes';

export const MAXIMUM_DOWNLOAD_TRIES = 50;
export const MAXIMUM_RETRIES_SHOW_WARNING = 3;
export const RETRY_REQUEST_INTERVAL = 5;
export const TIMEOUT_REPORT_MESSAGE = 'The read operation timed out';
export const REPORT_RUNNING_STATUS = 'RUNNING';

export const resources = {
  icon: Download,
  labels: {
    button: 'Export CSV',
    tooltip: {
      idle: 'This will download a CSV of your records based on the filters defined. Maximum file size is 10MB.',
      isDownloading: 'Large reports may take several minutes to generate.',
    },
  },
};

export const downloadReport = (payload: {
  fileName: string | undefined;
  data: any;
  fileType: FileType;
}) => {
  const blob = new Blob([payload.data], {
    type: payload.fileType,
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = payload.fileName || '';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const useDownloadReport = () => {
  const warningToastIdRef = useRef<ToastId>();
  const downloadLimitTries = useRef<number>(0);
  const [isDownloadingReport, setIsDownloadingReport] =
    useState<boolean>(false);
  const toast = useToast({ duration: 10000 });
  const [data, setData] = useState<JsonArray>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const reportLongOperation = () => {
    warningToastIdRef.current = toast({
      description:
        'The current operation is taking longer than expected to complete. Please wait.',
      status: 'warning',
      title: 'Report',
      duration: null,
    });
  };

  const clearLongOperationToast = () => {
    if (warningToastIdRef.current) toast.close(warningToastIdRef.current);
  };

  const reportAnError = () => {
    clearLongOperationToast();
    toast({
      description:
        'An unexpected error occurred when downloading your csv file. Please try again and contact Standvast Support if this issue persists.',
      status: 'error',
      title: 'Report',
    });
  };

  const reportPayloadTooLargeError = () => {
    clearLongOperationToast();
    toast({
      description:
        'Your file exceeded the limit of 10MB. Please adjust your filters and try again (ex. set Created Date to the last week).',
      status: 'error',
      title: 'Report Too Large',
    });
  };

  const getReportDataUntilSucceeded = async (
    reportId: string,
    filename: string,
    reportType: string
  ): Promise<AxiosResponse<DownloadReportResponse>> => {
    if (downloadLimitTries.current === MAXIMUM_RETRIES_SHOW_WARNING) {
      reportLongOperation();
    }
    downloadLimitTries.current++;

    const downloadReportResponse = await axios.get<
      any,
      AxiosResponse<DownloadReportResponse>
    >(
      `/report/download/${reportId}?filename=${filename}&report_type=${reportType}`
    );

    if (downloadLimitTries.current < MAXIMUM_DOWNLOAD_TRIES) {
      if (typeof downloadReportResponse.data !== 'string') {
        if (
          (downloadReportResponse.data as DownloadReportErrorResponse)
            .message === TIMEOUT_REPORT_MESSAGE ||
          (downloadReportResponse.data as DownloadReportFailureResponse)
            .status === REPORT_RUNNING_STATUS
        ) {
          await sleep(RETRY_REQUEST_INTERVAL);
          return getReportDataUntilSucceeded(reportId, filename, reportType);
        }
      }
    }

    return downloadReportResponse;
  };

  const createParameter = (
    parameterName: string,
    value?: string | null
  ): ReportParameter[] => {
    if (parameterName.endsWith('_range')) {
      const parameterNameWithoutRange = parameterName.replace('_range', '');
      const fromToValues = value?.split('_') || [];

      return [
        ...createParameter(
          `${parameterNameWithoutRange}_from`,
          fromToValues[0]
        ),
        ...createParameter(`${parameterNameWithoutRange}_to`, fromToValues[1]),
      ];
    }

    if (value) {
      return [
        {
          name: parameterName,
          value: String(value),
        },
      ];
    }

    return [{ name: parameterName }];
  };

  const handleBuildParameters = (
    parametersMap: Record<string, string>,
    parametersInUse: Record<string, string>
  ): ReportParameter[] => {
    const parameters: Record<string, string> = {
      ...Object.keys(parametersMap).reduce(
        (params, key) => ({ ...params, [key]: '' }),
        {}
      ),
      ...parametersInUse,
    };

    return Object.entries(parameters || {})
      .map(([key, value]) =>
        createParameter(_.snakeCase(parametersMap[key]), value)
      )
      .flat();
  };

  const handleDownloadReport = async (
    reportName: Reports,
    parameters: ReportParameter[] = [],
    reportType: 'csv' | 'json' = 'csv'
  ) => {
    downloadLimitTries.current = 0;
    updateProgress(reportType, true);

    try {
      const createReportResponse = await axios.post<
        any,
        AxiosResponse<CreateReportResponse>
      >('/report', {
        parameters: [
          ...parameters,
          { name: 'report_name', value: reportName },
          { name: 'report_type', value: reportType },
        ],
        report_name: reportName,
        report_type: reportType,
      });

      if (createReportResponse.data.report_id) {
        const downloadReportResponse = await getReportDataUntilSucceeded(
          createReportResponse.data.report_id,
          REPORT_FILENAMES[reportName],
          reportType
        );

        clearLongOperationToast();

        if (typeof downloadReportResponse.data === 'string') {
          const filename = downloadReportResponse.headers['content-disposition']
            ?.split('filename=')
            ?.at(-1);

          downloadReport({
            fileName: filename,
            data: downloadReportResponse.data,
            fileType: FileType.Csv,
          });

          updateProgress(reportType, false);
        } else if (Array.isArray(downloadReportResponse.data)) {
          setData(downloadReportResponse.data);
          updateProgress(reportType, false);
        } else {
          reportAnError();
          updateProgress(reportType, false);
        }
      } else {
        if (
          createReportResponse.data.status &&
          createReportResponse.data.status === 'PENDING'
        ) {
          return;
        }

        reportAnError();
        updateProgress(reportType, false);
      }
    } catch (error) {
      console.error(error);
      if (
        axios.isAxiosError(error) &&
        error.response?.status === HttpStatusCode.PayloadTooLarge
      ) {
        reportPayloadTooLargeError();
      } else {
        reportAnError();
      }
      updateProgress(reportType, false);
    }
  };

  const handleGetRefreshDate = (data: JsonArray): string => {
    const FIRST_ROW = 0;
    const REFRESH_DATE_COLUMN = -2;
    const refreshDate =
      data.length > 0 ? data[FIRST_ROW].at(REFRESH_DATE_COLUMN) : '';

    const refreshDateFormated = refreshDate?.match(
      /^(([\d]{2,4})(-)([\d]{1,2})(-)([\d]{1,2}))/
    )
      ? formatDate(refreshDate, 'MM/DD/YYYY HH:MM')
      : '';

    return refreshDateFormated === 'Invalid Date' ? '' : refreshDateFormated;
  };

  const handleGetTotalOfRecords = (data: JsonArray): number => {
    const FIRST_ROW = 0;
    const LAST_COLUMN = -1;

    return data?.length > 0
      ? parseInt(data[FIRST_ROW].at(LAST_COLUMN) || '0')
      : 0;
  };

  const updateProgress = (reportType: 'csv' | 'json', progress: boolean) => {
    if (reportType === 'csv') {
      setIsDownloadingReport(progress);
    } else {
      setIsFetching(progress);
    }
  };

  return {
    buildParameters: handleBuildParameters,
    getRefreshDate: handleGetRefreshDate,
    getTotalOfRecordsFromReport: handleGetTotalOfRecords,
    isDownloadingReport,
    isFetching,
    onDownloadReport: handleDownloadReport,
    resources,
    data,
  };
};
