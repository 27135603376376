import { useState } from 'react';
import {
  IconButton,
  ModalProps,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalFooterProps,
} from '@chakra-ui/react';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import { ButtonGroup } from './ButtonGroup';
import {
  persistModalIsFullScreen,
  retrieveModalIsFullScreen,
} from 'utils/storageUtils';

import SharedStyles from 'styles/shared.module.css';

type Props = Omit<ModalProps, 'onClose' | 'isOpen'> & {
  title?: string;
  onClose?: () => void;
  isOpen?: boolean;
  buttons?:
    | {
        label: string;
        handler: () => void;
        variant?: string;
        isDisabled?: boolean;
        isLoading?: boolean;
      }[]
    | React.ReactNode;
  children: React.ReactNode;
  showCloseButton?: boolean;
  modalBodyProps?: ModalBodyProps;
  modalContentProps?: ModalContentProps;
  modalHeaderProps?: ModalHeaderProps;
  modalFooterProps?: ModalFooterProps;
  scrollVisible?: boolean;
  fullscreen?: boolean;
};

export function Modal({
  title,
  children,
  isOpen,
  onClose,
  buttons,
  showCloseButton = true,
  modalBodyProps,
  modalContentProps,
  modalHeaderProps,
  modalFooterProps,
  scrollVisible,
  fullscreen,
  ...props
}: Props) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    typeof fullscreen !== 'undefined' ? fullscreen : retrieveModalIsFullScreen()
  );
  const footer = Array.isArray(buttons) ? (
    buttons.length > 0 ? (
      <ButtonGroup buttons={buttons} />
    ) : null
  ) : (
    buttons
  );
  const toggleFullScreen = () => {
    const toggled = !isFullScreen;
    setIsFullScreen(toggled);
    persistModalIsFullScreen(toggled);
  };

  return (
    <ChakraModal
      isOpen={isOpen ?? true}
      onClose={onClose ?? (() => {})}
      scrollBehavior="inside"
      size={['full', null, '6xl']}
      variant={isFullScreen ? 'full-screen' : ''}
      {...props}
    >
      <ModalOverlay />
      <ModalContent {...modalContentProps}>
        <IconButton
          variant="modalIconButton"
          aria-label={isFullScreen ? 'Exit full screen' : 'Enter full screen'}
          title={isFullScreen ? 'Exit full screen' : 'Enter full screen'}
          icon={
            isFullScreen ? (
              <FullscreenExit sx={{ height: '1.5em', width: '1.5em' }} />
            ) : (
              <Fullscreen sx={{ height: '1.5em', width: '1.5em' }} />
            )
          }
          onClick={toggleFullScreen}
        />
        {title && (
          <ModalHeader {...modalHeaderProps} data-header>
            {title}
          </ModalHeader>
        )}
        {showCloseButton && <ModalCloseButton data-close />}
        <ModalBody
          {...modalBodyProps}
          className={
            scrollVisible ? SharedStyles['horizontal-scroll'] : undefined
          }
        >
          {children}
        </ModalBody>

        {footer && (
          <ModalFooter data-footer {...modalFooterProps}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
}
