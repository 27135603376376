import { Box, BoxProps, HStack, Tag, Text } from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import {
  BAD_STATUS,
  INCOMPLETE_STATUS,
  PROCESSING_STATUS,
  SUCCESS_STATUS,
} from 'constants/order-statuses';
import { useMemo } from 'react';
import { SalesOrderStatus } from 'types/salesOrders';
import { StatusHistoryEntry } from 'types/status';
import { formatDate } from 'utils/dateUtils';

type Props = BoxProps & {
  history?: StatusHistoryEntry<SalesOrderStatus>[];
};

export function StatusChangeList({ history }: Props) {
  const currentStatus = history?.at(-1);
  const previousStatuses = history?.slice(0, -1)?.reverse();

  const currentStatusColorScheme = useMemo(() => {
    const status = (currentStatus?.status || '') as SalesOrderStatus;

    if (PROCESSING_STATUS.includes(status)) return 'gray';
    else if (INCOMPLETE_STATUS.includes(status)) return 'secondaryOrange';
    else if (BAD_STATUS.includes(status)) return 'red';
    else if (SUCCESS_STATUS.includes(status)) return 'green';
    return 'gray';
  }, [currentStatus]);

  return (
    <Box>
      <Text variant="boldBlue" fontSize="xl">
        Order Status
      </Text>
      <HStack py={1} spacing={2}>
        <Shimmer
          w="160px"
          h="30px"
          display="inline-block"
          verticalAlign="middle"
          isLoaded={!!history}
        >
          <Tag
            aria-label={`Current order status - ${currentStatusColorScheme}`}
            size="lg"
            fontSize="lg"
            variant="solid"
            colorScheme={currentStatusColorScheme}
          >
            {currentStatus?.status}
          </Tag>
          <Text fontSize="xl" color="primaryBlue.500" fontWeight="500">
            {formatDate(currentStatus?.status_date)}
          </Text>
        </Shimmer>
      </HStack>
      <Box as="ul" pl={4}>
        {previousStatuses?.map(({ status, status_date }) => (
          <Box as="li" key={`${status}_${status_date}`}>
            <Shimmer
              w="160px"
              h="30px"
              display="inline-block"
              verticalAlign="middle"
              isLoaded={!!history}
            >
              <InlineText mr={2}>{status}</InlineText>
              <InlineText>{formatDate(status_date)}</InlineText>
            </Shimmer>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
