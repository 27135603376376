import { FormSelect, FormSelectProps } from 'components/FormSelect';
import { useTenant } from 'queries/tenant/useTenant';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';

export const StorefrontSelect = (props: Omit<FormSelectProps, 'options'>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SalesOrder>();
  const { data } = useTenant();

  const storefronts = useMemo(() => {
    return (
      data?.storefronts?.map((item) => ({
        label: item.displayName,
        value: item.name,
      })) ?? []
    );
  }, [data]);

  return storefronts.length > 0 ? (
    <FormSelect
      label="Storefront"
      isRequired={storefronts.length > 0}
      placeholder="Please select a storefront"
      options={storefronts}
      error={errors.storefront?.message}
      {...register('storefrontName')}
      {...props}
    />
  ) : null;
};
