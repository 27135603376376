import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SalesOrderDetailDto } from 'types/salesOrders';
import { mapDtoToSalesOrder } from './mappers';

export function useSalesOrder(soNumber?: string | null) {
  return useQuery(
    ['salesOrder', soNumber],
    async () => {
      const { data } = await axios.get<SalesOrderDetailDto>(
        '/salesorder/details',
        {
          params: { so_number: soNumber },
        }
      );

      return data;
    },
    {
      select: mapDtoToSalesOrder,
      enabled: Boolean(soNumber),
    }
  );
}
