import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { UserProfileModal } from 'features/user-profile-modal/UserProfileModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useStore } from 'contexts/storeContext';
import { useMemo } from 'react';

export function Profile() {
  const {
    isOpen: isOpenProfile,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const { user, logout } = useAuth0();
  const [state, dispatch] = useStore();

  const isMissingProfileInfo = useMemo(
    () => !user?.family_name || !user?.name || !user?.email_address,
    [user]
  );

  const menuItems = [
    {
      text: 'Profile',
      onClick: onOpenProfile,
    },
    {
      text: 'Log Out',
      onClick: () => logout({ returnTo: window.location.origin }),
    },
  ];

  return (
    <>
      <Menu isOpen={isOpenMenu}>
        <MenuButton
          as={IconButton}
          icon={<AccountCircleOutlinedIcon />}
          title="Click here to edit user profile!"
          aria-label="User Profile Icon"
          variant="ghost"
          size="sm"
          onClick={onOpenMenu}
          onMouseOver={onOpenMenu}
          onMouseOut={onCloseMenu}
        />
        <MenuList
          onMouseOver={onOpenMenu}
          onMouseOut={onCloseMenu}
          mt="-8px"
          aria-label="Profile Menu"
          width="180px"
          minWidth="180px"
        >
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.text} onClick={menuItem.onClick}>
              {menuItem.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <UserProfileModal
        preventCloseWhenFormInvalid={isMissingProfileInfo}
        isOpen={
          isOpenProfile || (Boolean(state?.firstLogin) && isMissingProfileInfo)
        }
        onClose={onCloseProfile}
        onSubmit={() => dispatch({ firstLogin: false })}
      />
    </>
  );
}
