/* istanbul ignore file */
import {
  defaultShippingAccountCountry,
  usAndTerritories,
} from 'features/create-order/components/CarrierAccountSelect';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import { ShipVia } from 'types/shipVia';
import * as yup from 'yup';

export const basicSalesOrderInfoSchema = yup
  .object({
    soNumber: yup.string().label('Sales Order #').required().max(50),
    poNumber: yup
      .string()
      .label('Purchase Order #')
      .max(150)
      .nullable()
      .optional(),
    dueDate: yup.string().label('Ship By Date').optional().nullable(),
    instructions: yup
      .string()
      .label('Instructions')
      .max(250)
      .nullable()
      .optional(),
    shipVia: yup
      .string()
      .label('Carrier')
      .required()
      .max(50)
      .test(
        'is-valid-ship-via',
        () => 'Ship via is not valid for this FC/Country',
        function (state = '', { options: { context } }) {
          const isNodeOptionSelected =
            this.parent.warehouseID === SELECT_NODE_OPTION.code;
          const dataShipVias: ShipVia[] = context?.shipVias ?? [];
          const shippingAccounts =
            dataShipVias
              .find((item) => item.shipVia === state)
              ?.shippingAccounts?.filter((item) => {
                if (usAndTerritories.includes(this.parent.shipTo.country)) {
                  return (
                    item.countryCode === this.parent.shipTo.country &&
                    item.distributionCenterCode === this.parent.warehouseID
                  );
                } else {
                  //default to the US shipping account for international orders
                  return (
                    item.countryCode === defaultShippingAccountCountry &&
                    item.distributionCenterCode === this.parent.warehouseID
                  );
                }
              }) ?? [];
          return (
            this.parent.type === 'Freight' ||
            !state ||
            !this.parent.shipTo.country ||
            !this.parent.warehouseID ||
            isNodeOptionSelected ||
            (state &&
              this.parent.shipTo.country &&
              this.parent.warehouseID &&
              shippingAccounts.length > 0)
          );
        }
      ),
    warehouseID: yup.string().label('Warehouse').required(),
    giftMessage: yup
      .string()
      .optional()
      .nullable()
      .max(500)
      .label('Gift Message'),
    storefrontName: yup
      .string()
      .label('Storefront')
      .nullable()
      .optional()
      .test(
        'is-required-storefront',
        () => 'Storefront is required',
        function (state, { options: { context } }) {
          const storefrontsCount = context?.storefronts?.length;
          return !storefrontsCount
            ? true
            : storefrontsCount > 0 && Boolean(state);
        }
      ),
  })
  .required();
