import {
  FormSelect,
  FormSelectOption,
  FormSelectProps,
} from 'components/FormSelect';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';
import isEqual from 'lodash/isEqual';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import _uniqBy from 'lodash/uniqBy';
import { useUpdateEffect } from '@chakra-ui/react';

export const usAndTerritories = ['US', 'PR'];
export const defaultShippingAccountCountry = 'US';

function CarrierAccountSelect(props: Omit<FormSelectProps, 'options'>) {
  const [options, setOptions] = useState<FormSelectOption[]>([]);
  const {
    register,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<SalesOrder>();

  const { data: dataShipVias = [] } = useShipVias();

  const shipViaValue = watch('shipVia');
  const warehouseID = watch('warehouseID');
  const country = watch('shipTo.country');

  useEffect(() => {
    const shippingAccounts =
      dataShipVias.find((item) => item.shipVia === shipViaValue)
        ?.shippingAccounts ?? [];
    const filteredAccounts =
      warehouseID === SELECT_NODE_OPTION.code
        ? shippingAccounts
        : shippingAccounts.filter((item) => {
            if (usAndTerritories.includes(country)) {
              return (
                item.countryCode === country &&
                item.distributionCenterCode === warehouseID
              );
            } else {
              return (
                item.countryCode === defaultShippingAccountCountry &&
                item.distributionCenterCode === warehouseID
              );
            }
          });

    const newOptions = _uniqBy(
      filteredAccounts.map(({ carrierAccountUuid }) => ({
        value: carrierAccountUuid,
        label: carrierAccountUuid,
      })),
      'value'
    );

    if (!isEqual(options, newOptions)) {
      setOptions(newOptions);
    }
  }, [dataShipVias, options, shipViaValue, warehouseID, country]);

  useUpdateEffect(() => {
    if (options.length === 1) {
      setValue('carrierAccountUUID', options[0].value!);
    } else if (
      !options.some(
        (option) => option.value === getValues('carrierAccountUUID')
      )
    ) {
      setValue('carrierAccountUUID', '');
    }
    if (dirtyFields.shipVia) {
      trigger('carrierAccountUUID');
      trigger('shipVia');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <FormSelect
      {...props}
      {...register('carrierAccountUUID')}
      key={`carrierAccountUUID-${options.length}`}
      options={options}
      placeholder="Please select a code"
      label="Carrier Account UUID"
      isRequired={warehouseID !== SELECT_NODE_OPTION.code}
      tooltip="Select the default, unless this order requires a 3rd party billing (3P) option."
    />
  );
}

export default CarrierAccountSelect;
