import { Flex, IconButton, Text } from '@chakra-ui/react';
import { Close } from '@mui/icons-material';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  show?: boolean;
};

export const BANNER_HEIGHT = 100;

export function Banner({ children, onClose, show }: Props) {
  if (!show) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      bg="primaryOrange.500"
      justifyContent="space-between"
      position="fixed"
      px={8}
      py={4}
      top={0}
      w="full"
      zIndex={1}
    >
      <Text fontWeight="bold" textColor="white">
        {children}
      </Text>
      <IconButton
        _hover={{ bg: 'primaryOrange.600' }}
        aria-label="Close banner"
        border="none"
        icon={<Close />}
        onClick={onClose}
        textColor="white"
      />
    </Flex>
  );
}
