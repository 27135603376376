import {
  Box,
  Collapse,
  Flex,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { SalesOrder } from 'types/salesOrders';
import { Address } from './Address';
import { OrderInfoTable } from './OrderInfoTable';
import { OrderLinesTable } from './OrderLinesTable';
import { ErrorBoundary } from 'components/ErrorBoundary';
import SharedStyles from 'styles/shared.module.css';
import { Link } from 'react-router-dom';
import { OrderShippingBoxesTable } from './OrderShippingBoxesTable';
import { InlineText } from 'components/InlineText';
import { StatusChangeList } from './StatusChangeList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  order?: SalesOrder;
  parentOrder?: SalesOrder;
};

export function OrderDetails({ order, parentOrder }: Props) {
  const toast = useToast();
  const { shipFrom, shipTo } = order ?? {};
  const { isOpen: isVisibleShipTo, onToggle: onToggleVisibleShipTo } =
    useDisclosure();

  const EyeIcon = isVisibleShipTo ? VisibilityOffIcon : VisibilityIcon;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      Object.values(order?.shipTo ?? {}).join('\n')
    );
    toast({
      status: 'info',
      description: 'Ship to address copied',
    });
  };

  return (
    <>
      <ErrorBoundary>
        {order?.storefront?.displayName ? (
          <Box lineHeight={['shorter', null, 'base']}>
            <InlineText variant="boldBlue" fontSize="xl" mr={1}>
              Storefront:
            </InlineText>
            <InlineText fontSize="xl">
              {order?.storefront?.displayName}
            </InlineText>
          </Box>
        ) : null}
        <Flex
          justifyContent="space-between"
          flexWrap={['wrap', null, 'nowrap']}
        >
          {parentOrder ? (
            <Flex alignItems="center" gap={1} flexShrink={0}>
              <Text variant="boldBlue" fontSize="xl">
                Parent SO #:
              </Text>
              <Link
                aria-label={`Parent SO ${parentOrder.soNumber}`}
                to={`/orders/${parentOrder.soNumber}`}
              >
                <Text variant="link" textDecoration="underline" fontSize="lg">
                  {parentOrder.soNumber}
                </Text>
              </Link>
            </Flex>
          ) : null}
        </Flex>
      </ErrorBoundary>
      <SimpleGrid
        columns={[1, null, 3]}
        mt={5}
        mb={[5, null, 3]}
        spacing={[3, null, 2]}
      >
        <StatusChangeList history={order?.statusChangesHistory} />

        <Box>
          <Text variant="boldBlue" fontSize="xl">
            Ship From
          </Text>
          <ErrorBoundary>
            <Address address={shipFrom} />
          </ErrorBoundary>
        </Box>

        <Box>
          <ErrorBoundary>
            <Text
              variant="boldBlue"
              fontSize="xl"
              display="flex"
              alignItems="center"
            >
              Ship To
              <IconButton
                ml={0.5}
                aria-label="Toggle Visible Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<EyeIcon fontSize="small" />}
                onClick={onToggleVisibleShipTo}
              />
              <IconButton
                aria-label="Copy Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<ContentCopyIcon fontSize="small" />}
                onClick={copyToClipboard}
              />
            </Text>
            <Collapse in={isVisibleShipTo} animateOpacity unmountOnExit>
              <Address address={shipTo} />
            </Collapse>
          </ErrorBoundary>
        </Box>
      </SimpleGrid>

      <Box
        className={SharedStyles['horizontal-scroll']}
        mx={-2}
        overflow="auto"
      >
        <ErrorBoundary>
          <OrderInfoTable order={order} mt={[7, null, 5]} />
        </ErrorBoundary>
        <ErrorBoundary>
          <OrderLinesTable order={order} mt={30} />
        </ErrorBoundary>
        {order?.shippingBoxesDetail && order?.shippingBoxesDetail.length > 0 ? (
          <ErrorBoundary>
            <OrderShippingBoxesTable data={order!.shippingBoxesDetail} />
          </ErrorBoundary>
        ) : null}
      </Box>
    </>
  );
}
