import { FormSelect, FormSelectProps } from 'components/FormSelect';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';

export function ShipViaSelect(props: Omit<FormSelectProps, 'options'>) {
  const { register } = useFormContext<SalesOrder>();

  const { data: dataShipVias = [], isLoading } = useShipVias();

  const shipVias = useMemo(() => {
    return dataShipVias.map(({ shipVia }) => ({
      value: shipVia,
      label: shipVia,
    }));
  }, [dataShipVias]);

  return (
    <FormSelect
      {...props}
      {...register('shipVia')}
      key={`ship-via-${isLoading}`}
      isRequired
      label="Ship Via"
      placeholder="Please select a carrier"
      options={shipVias}
      tooltip="If set to Rate Shop, the cheapest ship method will be selected."
    />
  );
}
