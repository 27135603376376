/* istanbul ignore file */
import { Accordion, GridItem, SimpleGrid } from '@chakra-ui/react';
import { BasicInfoForm } from './BasicInfoForm';
import { FreightInfoForm } from './FreightInfoForm';
import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';
import { OrderLines } from 'features/order-lines/OrderLines';
import { InstructionsForm } from './InstructionsForm';
import { SectionCollapsable } from 'components/SectionCollapsable';

export const CreateFreightOrderForm: React.FC = () => {
  return (
    <Accordion defaultIndex={[0, 1, 2, 5]} allowMultiple>
      <SimpleGrid columns={1}>
        <GridItem>
          <SectionCollapsable title="General">
            <BasicInfoForm orderType="Freight" startTabIndex={0} />
          </SectionCollapsable>
        </GridItem>
        <GridItem mt={8}>
          <SectionCollapsable title="Ship To">
            <ShipToForm startTabIndex={9} />
            <InstructionsForm tabIndex={20} />
          </SectionCollapsable>
        </GridItem>
        <GridItem mt={8}>
          <SectionCollapsable title="Freight Information">
            <FreightInfoForm startTabIndex={20} />
          </SectionCollapsable>
        </GridItem>
        <GridItem mt={8}>
          <SectionCollapsable title="SKUs and quantities to ship">
            <OrderLines startTabIndex={36} />
          </SectionCollapsable>
        </GridItem>
      </SimpleGrid>
    </Accordion>
  );
};
