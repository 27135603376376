import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router-dom';

const HelpSupport = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const navigate = useNavigate();

  const menuItems = [
    {
      text: 'Templates',
      path: '/templates',
    },
  ];

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        as={IconButton}
        icon={<QuestionMarkIcon />}
        border="none"
        variant="ghost"
        fontSize="22px"
        size="sm"
        title="Click here for help!"
        aria-label="Help Support"
        ml={1}
        mb="2px"
        _focusVisible={{ boxShadow: 'unset' }}
        onClick={() => {
          onToggle();
        }}
        onMouseOver={() => {
          onOpen();
        }}
        onMouseOut={onClose}
      />
      <MenuList
        mt="-8px"
        onMouseOver={onOpen}
        onMouseOut={onClose}
        aria-label="Help Support Menu"
        width="180px"
        minWidth="180px"
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.path}
            onClick={() => {
              navigate(menuItem.path);
            }}
          >
            {menuItem.text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default HelpSupport;
