import { useEffect } from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { useSalesOrder } from 'queries/salesOrders';
import { useHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { CancelOrderPrompt } from './components/CancelOrder/CancelOrderPrompt';
import { OrderDetails } from './components/ViewOrder/OrderDetails';
import { ModifyOrder } from './components/ModifyOrder/ModifyOrder';
import {
  isCancellable,
  isReshipOrder,
  removeReshipSuffix,
} from 'utils/salesOrdersUtils';
import { ExceptionState } from 'components/ExceptionState';
import { AxiosError } from 'axios';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { sendViewSalesOrderDetailsEvent } from 'utils/monitorEvents';
import { useCreateReshipSalesOrder } from 'mutations/salesOrders/useCreateReshipSalesOrder';
import { usePortal } from 'hooks/usePortal';

type Props = {
  salesOrderNumber: string;
  onClose: () => void;
  onAbort: () => void;
  onEdit: () => void;
  onCancel: () => void;
  isCancelling: boolean;
  isEditing: boolean;
};

export function OrderDetailsModal(props: Props) {
  const { onOpen: handleOpen, ...disclosure } = useDisclosure();
  const {
    data: order,
    isFetching,
    error,
  } = useSalesOrder(props.salesOrderNumber);
  const { data: parentOrder, isFetching: isFetchingParentOrder } =
    useSalesOrder(
      isReshipOrder(props.salesOrderNumber)
        ? removeReshipSuffix(props.salesOrderNumber)
        : null
    );
  const [handleClose, setCanClose] = useHandlerInterceptor(() => {
    disclosure.onClose();
    props.onClose();
  });
  const toast = useToast();
  const { order: newRSOrder, create: createReshipOrder } =
    useCreateReshipSalesOrder(order);

  const { isPortalReadOnly } = usePortal();

  useEffect(() => {
    if (!props.isEditing) setCanClose(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditing]);

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  useEffect(
    () => sendViewSalesOrderDetailsEvent(order?.soNumber ?? ''),
    [order?.soNumber]
  );

  const doneButton = {
    label: 'Done',
    handler: handleClose,
  };
  const viewingModeButtons = [
    {
      label: 'Modify SO',
      handler: () => {
        props.onEdit();
      },
      visible: ['Received', 'Received-Updated'].includes(order?.status ?? ''),
      isDisabled:
        !order || (order.type === 'Parcel' && !order.carrierAccountUUID),
      buttonProps: {
        'aria-label': 'Modify Sales Order',
      },
    },
    {
      label: 'Cancel SO',
      handler: () => {
        props.onCancel();
      },
      disabledStateHandler: () =>
        toast({
          status: 'error',
          title: 'Error',
          description: 'Cannot cancel at this time as labelling is complete.',
        }),
      isDisabled: !order || !isCancellable(order),
      buttonProps: {
        'aria-label': 'Cancel Sales Order',
      },
    },
    {
      label: 'Reship',
      tooltip:
        'Duplicate this sales order. Does not modify the existing sales order.',
      handler: () => {
        createReshipOrder();
      },
      buttonProps: {
        'aria-label': 'Reship',
        isDisabled: !newRSOrder,
      },
    },
    doneButton,
  ];

  return (
    <Modal
      fullscreen
      title={
        props.isCancelling
          ? ''
          : `${props.isEditing ? 'Modify ' : ''}Sales Order #${
              props.salesOrderNumber
            }`
      }
      isOpen={disclosure.isOpen}
      onClose={handleClose}
      buttons={
        props.isCancelling || props.isEditing || isPortalReadOnly
          ? [doneButton]
          : viewingModeButtons
      }
      size={[
        'full',
        null,
        props.isCancelling ? '2xl' : props.isEditing ? '7xl' : '6xl',
      ]}
      showCloseButton={!props.isCancelling}
      modalBodyProps={{
        display: props.isCancelling ? ['flex', null, 'block'] : 'block',
        mb: props.isCancelling ? -6 : 0,
      }}
      modalHeaderProps={{
        'aria-label': props.isCancelling
          ? ''
          : `${props.isEditing ? 'Modify ' : ''}Sales Order #${
              props.salesOrderNumber
            }`,
      }}
      scrollVisible={!props.isEditing}
    >
      {(error as AxiosError)?.response?.status === 404 ? (
        <ExceptionState customTitle="Order Not Found" stateType="empty" />
      ) : props.isCancelling && order ? (
        <ErrorBoundary>
          <CancelOrderPrompt
            order={order}
            onConfirm={handleClose}
            onAbort={props.onAbort}
          />
        </ErrorBoundary>
      ) : props.isEditing && order ? (
        <ErrorBoundary>
          <ModifyOrder
            order={order}
            onConfirm={props.onAbort}
            onAbort={props.onAbort}
            setCanClose={setCanClose}
          />
        </ErrorBoundary>
      ) : (
        <OrderDetails
          order={isFetching || isFetchingParentOrder ? undefined : order}
          parentOrder={
            isFetching || isFetchingParentOrder ? undefined : parentOrder
          }
        />
      )}
    </Modal>
  );
}
