import { SalesOrderStatus } from 'types/salesOrders';

export const STATUS: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.Received,
  SalesOrderStatus.ReceivedUpdated,
  SalesOrderStatus.PickingStarted,
  SalesOrderStatus.Picked,
  SalesOrderStatus.Labelled,
  SalesOrderStatus.Loaded,
  SalesOrderStatus.PickedShort,
  SalesOrderStatus.CarrierError,
  SalesOrderStatus.CancellationRequested,
  SalesOrderStatus.LoadedShort,
  SalesOrderStatus.Shipped,
  SalesOrderStatus.ShippedPartial,
  SalesOrderStatus.ShippedShort,
]);

export const PENDING_STATUSES: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.Received,
  SalesOrderStatus.ReceivedUpdated,
  SalesOrderStatus.PickingStarted,
  SalesOrderStatus.Picked,
  SalesOrderStatus.Labelled,
  SalesOrderStatus.Loaded,
  SalesOrderStatus.PickedShort,
  SalesOrderStatus.CarrierError,
  SalesOrderStatus.CancellationRequested,
  SalesOrderStatus.LoadedShort,
]);

export const PROCESSING_STATUS: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.Received,
  SalesOrderStatus.ReceivedUpdated,
  SalesOrderStatus.PickingStarted,
  SalesOrderStatus.Picked,
  SalesOrderStatus.Labelled,
]);

export const INCOMPLETE_STATUS: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.PickedShort,
  SalesOrderStatus.LoadedShort,
  SalesOrderStatus.ShippedPartial,
  SalesOrderStatus.ShippedShort,
]);

export const BAD_STATUS: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.CarrierError,
  SalesOrderStatus.CancellationRequested,
]);

export const SUCCESS_STATUS: readonly SalesOrderStatus[] = Object.freeze([
  SalesOrderStatus.Loaded,
  SalesOrderStatus.Shipped,
]);
