import { useMemo, useEffect, useRef } from 'react';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { SalesOrder, SalesOrderType } from 'types/salesOrders';
import { parseDate } from 'utils/dateUtils';
import { FormSelect } from 'components/FormSelect';
import {
  SELECT_NODE_OPTION,
  useWarehouses,
} from 'queries/warehouses/useWarehouses';
import ShipOptionSelect from './ShipOptionSelect';
import { isReshipOrder } from 'utils/salesOrdersUtils';
import { StorefrontSelect } from './StorefrontSelect';
import { FormDatePicker } from 'components/FormDatePicker';
import { ShipViaSelect } from './ShipViaSelect';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';

type Props = {
  orderType: SalesOrderType;
  startTabIndex: number;
};

export const BasicInfoForm: React.FC<Props> = ({
  orderType,
  startTabIndex,
}) => {
  const containerRef = useRef(null);
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext<SalesOrder>();
  const draftOrder = getValues();

  const { data = [], isFetched } = useWarehouses({
    includeSelectNodeOption: orderType === 'Parcel',
  });

  const dueDateValue = watch('dueDate');
  const shipViaValue = watch('shipVia');

  const warehouses = useMemo(
    () =>
      data.map(({ code, address, displayText }) => ({
        value: code,
        label: displayText,
        address,
      })),
    [data]
  );

  useEffect(() => {
    const { warehouseID } = getValues();
    if (orderType === 'Freight') {
      setValue('shipVia', 'UNSP');
      if (warehouseID === SELECT_NODE_OPTION.code) setValue('warehouseID', '');
    } else if (orderType === 'Parcel' && !warehouseID) {
      setValue('warehouseID', SELECT_NODE_OPTION.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  return (
    <SimpleGrid ref={containerRef} columns={[1, 2]} columnGap={8}>
      <GridItem>
        <FormInput
          tabIndex={startTabIndex + 1}
          label="Sales Order #"
          placeholder="Please enter an SO#"
          {...register('soNumber')}
          isReadOnly={Boolean(isReshipOrder(draftOrder.soNumber))}
          isRequired
          tooltip="The order number from your system."
        />
        <FormSelect
          key={`warehouseID-${isFetched}`}
          label="Warehouse"
          isRequired
          tabIndex={startTabIndex + 3}
          placeholder="Please select a location"
          {...register('warehouseID')}
          options={warehouses}
          tooltip="If set to Automatic, the warehouse closest to the Ship To address with the necessary inventory will be selected."
        />
        {orderType === 'Freight' ? (
          <FormDatePicker
            tabIndex={startTabIndex + 7}
            tooltip="Leave blank if pickup will be arranged later."
            label="Ship By Date"
            aria-label="Ship By Date"
            placeholder="Please select a date"
            isClearable
            variant="flushed"
            defaultValue={parseDate(dueDateValue)}
            onChange={(value) =>
              setValue('dueDate', value, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            error={errors.dueDate?.message}
          />
        ) : null}
      </GridItem>
      <GridItem>
        <StorefrontSelect tabIndex={startTabIndex + 2} />
        {orderType === 'Parcel' && (
          <ShipViaSelect tabIndex={startTabIndex + 4} />
        )}
        {shipViaValue === RATE_SHOP_OPTION ? (
          <ShipOptionSelect tabIndex={startTabIndex + 5} />
        ) : null}
        <FormInput
          tabIndex={startTabIndex + 6}
          label="Purchase Order #"
          placeholder="Please enter a PO#"
          {...register('poNumber')}
          tooltip="Optional. A secondary reference number for this order."
        />
      </GridItem>
    </SimpleGrid>
  );
};
