import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import CountrySelect from './CountrySelect';

type Props = {
  startTabIndex: number;
};

export const ShipToForm: React.FC<Props> = ({ startTabIndex }) => {
  const { register, watch } = useFormContext();

  const country = watch('shipTo.country');

  return (
    <SimpleGrid columns={[1, 1, 2]} columnGap={8} aria-label="Ship To">
      <GridItem colSpan={[1, 1, 2]}>
        <FormInput
          tabIndex={startTabIndex + 1}
          label="Name"
          {...register('shipTo.name')}
          placeholder="Please enter a name"
          isRequired
        />
      </GridItem>
      <FormInput
        tabIndex={startTabIndex + 2}
        label="Address Line 1"
        {...register('shipTo.address')}
        placeholder="Please enter the address line 1"
        isRequired
      />
      <FormInput
        tabIndex={startTabIndex + 3}
        label="Address Line 2"
        {...register('shipTo.address2')}
        placeholder="Please enter the address line 2"
      />
      <FormInput
        tabIndex={startTabIndex + 4}
        label="City"
        {...register('shipTo.city')}
        placeholder="Enter city"
        isRequired
      />
      <FormInput
        isRequired={country === 'US'}
        tabIndex={startTabIndex + 5}
        label="State"
        {...register('shipTo.state')}
        placeholder="Enter state"
      />
      <FormInput
        tabIndex={startTabIndex + 6}
        label="ZIP/Postal Code"
        {...register('shipTo.zipCode')}
        placeholder="Enter zip/postal code"
        isRequired
      />
      <CountrySelect tabIndex={startTabIndex + 7} />
      <FormInput
        isRequired={country !== 'US'}
        tabIndex={startTabIndex + 8}
        label="Customer Telephone"
        {...register('shipTo.phoneNumber')}
        placeholder="Please enter a number"
      />
      <FormInput
        tabIndex={startTabIndex + 9}
        label="Customer Email"
        {...register('shipTo.email')}
        placeholder="Please enter an email address"
      />
    </SimpleGrid>
  );
};
