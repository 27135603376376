import { useMemo } from 'react';
import { Column } from 'react-table';
import { ItemMaster } from 'types/itemMaster';
import { useGridSorting } from 'hooks/useGridSorting';
import { useGridPagination } from 'hooks/useGridPagination';
import { ItemMasterPageParams } from 'features/item-master-list/itemMasterPageParams';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { PaginatedTable } from 'components/PaginatedTable';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { BigLoading } from 'components/BigLoading';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { useItemMasters } from 'queries/itemMasters/useItemMasters';
import { useNavigate } from 'react-router-dom';
import { BOOLEAN_OPTIONS } from 'constants/booleanOptions';

export interface ItemMastersGridProps extends ItemMasterPageParams {}

export function ItemMastersGrid(props: ItemMastersGridProps) {
  const navigate = useNavigate();
  const buttons = [
    {
      label: 'Upload CSV',
      variant: 'cta',
      handler: () => {
        navigate('/item-masters/create');
      },
    },
  ];

  const gridSorting = useGridSorting<ItemMaster>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });

  const columnsMetaData: { [key: string]: ColumnMetaData } = {
    sku: { dataType: 'text' },
    upc: { dataType: 'text' },
    description: { dataType: 'text' },
    weight: { dataType: 'number' },
    length: { dataType: 'number' },
    width: { dataType: 'number' },
    height: { dataType: 'number' },
    cubicFeet: { dataType: 'number' },
    isConveyable: {
      dataType: 'select',
      selectOptions: BOOLEAN_OPTIONS,
    },
    hazmatUnNumber: { dataType: 'text' },
    itemValue: { dataType: 'number' },
    requiresLotControl: {
      dataType: 'select',
      selectOptions: BOOLEAN_OPTIONS,
    },
    tariffNumber: { dataType: 'text' },
    customsValue: { dataType: 'number' },
    commodityDescription: { dataType: 'text' },
    countryOfManufacture: { dataType: 'text' },
    customsWeight: { dataType: 'number' },
  };

  const gridFiltering = useGridFiltering<ItemMaster>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const queryState = useItemMasters(
    { skip: gridPagination.skip, limit: gridPagination.limit },
    gridFiltering.filters,
    gridSorting.sort
  );

  const { widthWindow, getColumnWidth } = useLayoutPaginatedTable(3450);

  const columns = useMemo<Column<ItemMaster>[]>(
    () => [
      {
        accessor: 'sku',
        Header: 'SKU',
        width: getColumnWidth(10, 180),
      },
      {
        Header: 'UPC',
        accessor: 'upc',
        width: getColumnWidth(10, 180),
        helpText:
          "The Universal Product Code (UPC) is a unique 12-digit number assigned to each individual product sold in stores and online. It's used to identify and track products as they are sold, shipped, and received. They're typically found on product labels and packaging below the barcode",
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: getColumnWidth(10, 250),
        helpText: 'What the item is. E.g. Womens Flex Fit Quarterzip Blue S',
      },
      {
        Header: 'Weight',
        accessor: 'weight',
        isNumeric: true,
        width: getColumnWidth(6, 150),
        helpText:
          'Weight in lbs of an individual item (e.g. for an individual toaster in a case of 12 toasters).',
      },
      {
        Header: 'Length',
        accessor: 'length',
        isNumeric: true,
        width: getColumnWidth(6, 150),
        helpText:
          'Length in inches of an individual item (e.g. for an individual toaster in a case of 12 toasters).',
      },
      {
        Header: 'Width',
        accessor: 'width',
        isNumeric: true,
        width: getColumnWidth(6, 150),
        helpText:
          'Width in inches of an individual item (e.g. for an individual toaster in a case of 12 toasters).',
      },
      {
        Header: 'Height',
        accessor: 'height',
        isNumeric: true,
        width: getColumnWidth(6, 150),
        helpText:
          'Height in inches of an individual item (e.g. for an individual toaster in a case of 12 toasters).',
      },
      {
        Header: 'Cubic Feet (cuft)',
        accessor: 'cubicFeet',
        isNumeric: true,
        width: getColumnWidth(6, 230),
        helpText:
          'Cubic feet, a measure of volume indicating how much room the item takes up.',
      },
      {
        Header: 'Is Conveyable',
        id: 'isConveyable',
        accessor: (data) => (data.isConveyable ? 'True' : 'False'),
        width: getColumnWidth(10, 200),
        helpText:
          "True means the item meets the dimension, weight, and shape requirements for the material handling systems. False means the item is a non-conveyable shipment, meaning it doesn't meet the aforementioned requirements and necessitates manual handling processes",
      },
      {
        Header: 'Hazmat UN Number',
        accessor: 'hazmatUnNumber',
        width: getColumnWidth(9, 250),
        helpText:
          'Located on the back or sides of trailers or other containers, UN numbers (or UN IDs) are four-digit numbers ranging from 0004-3534 that identify dangerous goods or hazardous substances — such as explosives, flammable liquids, or toxic substances — in the framework of international transport.',
      },
      {
        Header: 'Item Value',
        accessor: 'itemValue',
        isNumeric: true,
        width: getColumnWidth(7, 160),
      },
      {
        Header: 'Requires Lot',
        id: 'requiresLotControl',
        accessor: (data) => (data.requiresLotControl ? 'True' : 'False'),
        width: getColumnWidth(7, 190),
        helpText:
          'Lot control involves tracking each item that enters the warehouse by assigning numbers as "Lot IDs." A "lot" is a batch of items recieved, stored, or shipped from the warehouse. Lot control is important for items that a perishable, serialized, or highly regulated.',
      },
      {
        Header: 'Tariff Number',
        accessor: 'tariffNumber',
        width: getColumnWidth(7, 200),
        helpText:
          'Also known as harmonized system (HS) codes. Customs agents use these 6 to 10-digit codes to evaluate the content of imported and exported shipments, meaning that the proper code must be on your official shipping documents. Codes are based on goods descriptions.',
      },
      {
        Header: 'Customs Value',
        accessor: 'customsValue',
        isNumberic: true,
        width: getColumnWidth(7, 210),
        helpText:
          'Enter the total value of the item for customs purposes. This will always be USD.',
      },
      {
        Header: 'Commodity Description',
        accessor: 'commodityDescription',
        width: getColumnWidth(7, 290),
        helpText:
          'Provide a detailed description of the item for customs classification.',
      },
      {
        Header: 'Country Of Manufacture',
        accessor: 'countryOfManufacture',
        width: getColumnWidth(7, 290),
        helpText:
          'Select the country where the item was manufactured. This will always be the two-digit ISO code.',
      },
      {
        Header: 'Customs Weight',
        accessor: 'customsWeight',
        isNumberic: true,
        width: getColumnWidth(7, 220),
        helpText:
          'Enter the weight of the item for customs purposes. This will always be LBs.',
      },
    ],
    [getColumnWidth]
  );

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Text mb={[buttons.length ? 4 : 0, 0]} as="h2" variant="pageTitle">
          Item Master
        </Text>
        {buttons.length ? (
          <ButtonGroup
            buttons={buttons}
            wrapperProps={{
              display: 'flex',
              flexDirection: ['column-reverse', null, 'row'],
              width: 'auto',
            }}
            commonButtonProps={{ fontSize: 'lg' }}
            commonButtonWrapperProps={{ mr: 0 }}
          />
        ) : null}
      </Flex>
      <PaginatedTable
        columns={columns}
        gridPagination={gridPagination}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        queryState={queryState}
        tableName="item_master_list"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
