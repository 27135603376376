/* istanbul ignore file */
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { VALID_COUNTRIES } from 'constants/countriesAndStates';
import { SalesOrder } from 'types/salesOrders';

type Props = {
  editable?: boolean;
  startTabIndex: number;
};

export const BolBillingAddressForm: React.FC<Props> = ({
  editable = true,
  startTabIndex,
}) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <SimpleGrid
      columns={[1, 1, 2]}
      columnGap={8}
      aria-label="BOL Billing Address"
    >
      <GridItem colSpan={[1, 1, 2]}>
        <FormInput
          tabIndex={startTabIndex + 1}
          label="Name"
          {...register('bolData.billingAddress.name')}
          placeholder="Please enter a name"
          isReadOnly={!editable}
        />
      </GridItem>
      <FormInput
        tabIndex={startTabIndex + 2}
        label="Address Line 1"
        {...register('bolData.billingAddress.address')}
        placeholder="Please enter the address line 1"
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 3}
        label="Address Line 2"
        {...register('bolData.billingAddress.address2')}
        placeholder="Please enter the address line 2"
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 4}
        label="City"
        {...register('bolData.billingAddress.city')}
        placeholder="Enter city"
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 5}
        label="State"
        {...register('bolData.billingAddress.state')}
        placeholder="Enter state"
        isReadOnly={!editable}
      />
      <FormInput
        tabIndex={startTabIndex + 6}
        label="ZIP/Postal Code"
        {...register('bolData.billingAddress.zipCode')}
        placeholder="Enter zip/postal code"
        isReadOnly={!editable}
      />
      <FormSelect
        tabIndex={startTabIndex + 7}
        label="Country"
        {...register('bolData.billingAddress.country')}
        options={VALID_COUNTRIES}
        placeholder="Enter country"
        isReadOnly={!editable}
      />
      <GridItem colSpan={[1, 1, 2]}>
        <FormInput
          tabIndex={startTabIndex + 8}
          label="Telephone"
          {...register('bolData.billingAddress.phoneNumber')}
          placeholder="Please enter a number"
          isReadOnly={!editable}
        />
      </GridItem>
    </SimpleGrid>
  );
};
