import { GridItem } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import ShipOptionSelect from 'features/create-order/components/ShipOptionSelect';
import { ShipViaSelect } from 'features/create-order/components/ShipViaSelect';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';

type Props = {
  startTabIndex: number;
};

export const ShipViaForm: React.FC<Props> = ({ startTabIndex }) => {
  const { watch } = useFormContext();
  const shipViaValue = watch('shipVia');

  return (
    <>
      <GridItem>
        <ShipViaSelect tabIndex={startTabIndex + 1} />
      </GridItem>
      {shipViaValue === RATE_SHOP_OPTION && (
        <GridItem>
          <ShipOptionSelect tabIndex={startTabIndex + 3} />
        </GridItem>
      )}
    </>
  );
};
