import { Flex, Link, Box, useToast, FlexProps } from '@chakra-ui/react';
import MailToIcon from '@mui/icons-material/ContentCopy';

export type CopyableEmailProps = FlexProps & {
  emailAddress: string;
};

export const CopyableEmail = ({
  emailAddress,
  ...props
}: CopyableEmailProps) => {
  const toast = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(emailAddress);
    toast({
      status: 'info',
      description: 'Email copied to the clipboard.',
    });
  };

  return (
    <Flex
      display="inline-flex"
      mt={4}
      fontSize="md"
      color="primaryBlue.500"
      {...props}
    >
      <Link fontWeight="bold" href={`mailto:${emailAddress}`} mr={2}>
        {emailAddress}
      </Link>
      <Box onClick={copyToClipboard} cursor="pointer">
        <MailToIcon fontSize="small" />
      </Box>
    </Flex>
  );
};
