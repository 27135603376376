import { ComponentStyleConfig } from '@chakra-ui/react';

export const buttonTheme: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'semibold',
    borderRadius: 'base',
    cursor: 'pointer',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 3,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: 'lg',
      px: 8,
      py: 5,
      borderRadius: 'md',
    },
    block: {
      flex: 1,
      fontSize: 'md',
      py: 4,
      borderRadius: 'md',
      maxH: 50,
    },
  },
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'secondaryTeal.500',
      color: 'primaryBlue.500',
    },
    cta: {
      bg: 'secondaryOrange.500',
      color: 'white',
      _hover: {
        bg: 'secondaryOrange.600',
        _disabled: {
          bg: 'secondaryOragen.300',
        },
      },
      _disabled: {
        bg: 'secondaryOragen.300',
      },
    },
    iconButton: {
      transform: 'scale(0.7)',
      color: 'primaryBlue.500',
    },
    redSquareButton: {
      backgroundColor: 'secondaryOrange.500',
      borderWidth: 0,
      borderRadius: 0,
      color: 'white',
      py: 0,
      px: 5,
      h: 10,
      _hover: {
        bgColor: 'secondaryOrange.700',
        _disabled: {
          bgColor: 'secondaryOrange.500',
        },
      },
    },
    supportButton: {
      justifyContent: 'left',
      p: 0,
      w: '55%',
      color: 'primaryBlue.500',
      _hover: {
        bgColor: 'gray.100',
      },
    },
    blueNavButton: {
      justifyContent: 'left',
      p: 0,
      color: 'primaryBlue.500',
      _hover: {
        bgColor: 'gray.100',
      },
    },
    link: {
      color: 'primaryBlue.500',
    },
    modalIconButton: {
      color: 'primaryBlue.500',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'primaryBlue.100',
      margin: ['12px 0 0 12px', null, '24px 0 0 24px'],
      minWidth: 'auto',
      maxWidth: 'fit-content',
      height: 'auto',
      _hover: {
        backgroundColor: 'secondaryGray.900',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};
