import {
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import {
  FORECAST_URL,
  CREATE_ITEM_MASTER_URL,
  PURCHASE_ORDER_URL,
  SALES_ORDER_URL,
  SHIPPING_ACCOUNT_SETUP_URL,
} from 'constants/templateLinks';
import withLayout from 'hoc/withLayout';
import { TemplateItem } from 'layouts/components/NavMenu';

export const templateItems: TemplateItem[] = [
  {
    text: 'Sales Order',
    pathname: SALES_ORDER_URL,
  },
  {
    text: 'Purchase Order',
    pathname: PURCHASE_ORDER_URL,
  },
  {
    text: 'Item Master',
    pathname: CREATE_ITEM_MASTER_URL,
  },
  {
    text: 'Forecast',
    pathname: FORECAST_URL,
  },
  {
    text: 'Shipping Account Setup',
    pathname: SHIPPING_ACCOUNT_SETUP_URL,
  },
];

const TemplatesPage = () => {
  return (
    <Box>
      <Flex
        gap={{ base: 12, lg: 24 }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex direction="column" flex={1} gap={4} position="relative">
          <Text
            as="h2"
            aria-label="Templates"
            variant="pageTitle"
            mb={{ base: 0, lg: 6 }}
          >
            Templates
          </Text>
          <Text>
            Save a copy of the templates below and email them to your account
            manager for assistance with bulk uploads.
          </Text>
          <Box mt={4}>
            <UnorderedList listStyleType="none">
              {templateItems.map((templateItem) => (
                <ListItem mb={2} key={templateItem.pathname}>
                  <Link
                    href={templateItem.pathname}
                    target="_blank"
                    title={templateItem.text}
                  >
                    <Text
                      variant="boldBlue"
                      fontSize="md"
                      display="inline-block"
                    >
                      -&nbsp;
                    </Text>
                    <Text
                      variant="boldBlue"
                      fontSize="md"
                      display="inline-block"
                      textDecoration="underline"
                    >
                      {templateItem.text}
                    </Text>
                  </Link>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default withLayout(TemplatesPage);
